/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"


import containerStyles from "../components/main.module.scss";



const grid = ({children}) => (
		<div className={containerStyles.item}>
			{children}
		</div>
)

export default grid