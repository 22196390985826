import React from "react"

import Grid from "../components/grid";

import containerStyles from "../components/main.module.scss";

import ione from '../images/selfish.png';
import itwo from '../images/nat-west.png';
import ithree from '../images/save-the-children.png';
import ifour from '../images/articlten.png';
import lfive from '../images/stoli.png';
import lsix from '../images/adepto.png';
import lseven from '../images/jamie.png';
import leight from '../images/paradise.png';
import lnine from '../images/hot.png';
import lten from '../images/fm-artist.png';
import leleven from '../images/google.png';
import letwel from '../images/danmak.png';



const gridLayout = ({children}) => (
	
	
	<div className={containerStyles.werk} id="werk">
					<h2>{"Who we work with"}</h2>
					
					<div className={containerStyles.grid}>
						<Grid>
							<img src={ione} alt="Selfish Mother" />
						</Grid>
						<Grid>
							<img src={itwo} alt="NatWest" />
						</Grid>
						<Grid>
							<img src={ithree} alt="Save the Children" />
						</Grid>
						<Grid>
							<img src={ifour} alt="Article Ten" />
						</Grid>
						<Grid>
							<img src={lfive} alt="Stoli Group" />
						</Grid>
						<Grid>
							<img src={lsix} alt="Adepto" />
						</Grid>
						<Grid>
							<img src={lseven} alt="Jamie Jones" />
						</Grid>
						<Grid>
							<img src={leight} alt="Paradise Live" />
						</Grid>
						<Grid>
							<img src={lnine} alt="Hot Creations" />
						</Grid>
						<Grid>
							<img src={lten} alt="FM artists" />
						</Grid>
						<Grid>
							<img src={leleven} alt="Google"  />
						</Grid>
						<Grid>
							<img src={letwel} alt="The Geopligical Society" />
						</Grid>
					</div>
					
					</div>
	
)


export default gridLayout