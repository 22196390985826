import React from "react"

import scrollTo from "gatsby-plugin-smoothscroll"

import containerStyles from "../components/main.module.scss"

const Hero = ({ children }) => (
  <div className={containerStyles.innercontainer + " " + containerStyles.hero}>
    <div className={containerStyles.herocontainer}>
      <img
        alt="Curious People Projects"
        src={require("../images/cpp-logo.png")}
        style={{ maxWidth: 720 }}
      />
      <h2>
        Curious People Projects plans, designs and builds presences online.
      </h2>

      <div className={containerStyles.button}>
        <div onClick={() => scrollTo("#contact")}>GET IN TOUCH</div>
      </div>
    </div>
  </div>
)

export default Hero
