import React from "react"
//import Img from 'react-image'
//import { Link } from "gatsby"
import axios from "axios"
import * as qs from "query-string"

import Layout from "../components/layout"
import GridLayout from "../components/gridlayout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import containerStyles from "../components/main.module.scss"
import scrollTo from "gatsby-plugin-smoothscroll"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.domRef = React.createRef()
    this.state = { feedbackMsg: null }
  }

  handleSubmit(event) {
    // Do not submit form via HTTP, since we're doing that via XHR request.
    event.preventDefault()
    // Loop through this component's refs (the fields) and add them to the
    // formData object. What we're left with is an object of key-value pairs
    // that represent the form data we want to send to Netlify.
    const formData = {}
    Object.keys(this.refs).map((key) => (formData[key] = this.refs[key].value))

    // Set options for axios. The URL we're submitting to
    // (this.props.location.pathname) is the current page.
    const axiosOptions = {
      url: this.props.location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData),
    }

    // Submit to Netlify. Upon success, set the feedback message and clear all
    // the fields within the form. Upon failure, keep the fields as they are,
    // but set the feedback message to show the error state.
    axios(axiosOptions)
      .then((response) => {
        this.setState({
          feedbackMsg: "Form sent successfully!",
        })
        this.domRef.current.reset()
      })
      .catch((err) =>
        this.setState({
          feedbackMsg: "Form could not be submitted.",
        })
      )
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Curious People Projects"
          keywords={[
            `webdesign`,
            `web development`,
            `react`,
            `WordPress`,
            `Shopify`,
            `UK`,
            `London`,
            `Hackney`,
          ]}
        />
        <Hero />
        <svg
          onClick={() => scrollTo("#werk")}
          className={containerStyles.arrows}
        >
          <path strokeLinecap="round" className="a1" d="M0 0 L20 22 L40 0" />
        </svg>

        <GridLayout />
        <div
          className={
            containerStyles.innercontainer +
            " " +
            containerStyles.contact_container
          }
        >
          <div className={containerStyles.formintro} id="contact">
            <h2>{"Let's Talk"}</h2>

            <p>
              Thanks for taking the time to contact us. Please send us some
              information on your project so we can determine how best to help
              you.
            </p>
          </div>

          <div className={containerStyles.form}>
            {this.state.feedbackMsg && <h2>{this.state.feedbackMsg}</h2>}

            <form
              ref={this.domRef}
              name="Contact Form"
              method="POST"
              data-netlify="true"
              onSubmit={(event) => this.handleSubmit(event)}
            >
              <input
                ref="form-name"
                type="hidden"
                name="form-name"
                value="Contact Form"
              />
              <div className={containerStyles.contactrow_half}>
                <label>Your Name</label>
                <input ref="name" required type="name" name="name" />
              </div>
              <div
                className={
                  containerStyles.contactrow_half +
                  " " +
                  containerStyles.contactrow_right
                }
              >
                <label>Your Email</label>
                <input ref="email" required type="email" name="email" />
              </div>
              <div className={containerStyles.contactrow}>
                <label>Message</label>
                <textarea required ref="message" name="message" />
              </div>
              <div className={containerStyles.contactrow}>
                <button type="submit">GET IN TOUCH</button>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
